export  const Data = {
    mainStart : false,
    winStart : false,
    designSec:false,
    devSec:false,
    cmsSec:false,
    browserWinArray:[],
    checkBrowserZindex:[],
    
    myinfo:true, 
    emailAccount:false, 
    socialMedia:false,
    vision:false,
    history:false,
    goal:false,

    objective:true,
    workExp:false,
    skills:false,
    portfolio:false,
    education:false, 

    closeBigVideo:false,
    smallVideo:true,

    bigImg:false,
    imgName:'./',
}

